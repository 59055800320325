import styled from '@emotion/styled';
import { Box, Button, Drawer as MuiDrawer, Stack, Theme, Typography as MuiTypography } from '@mui/material';
import { withoutProps } from 'utils';

import { DrawerProps } from '.';

export const Drawer = styled(
  MuiDrawer,
  withoutProps('open')
)(
  ({ theme, open, side }: { theme?: Theme; open: boolean; side: DrawerProps['position'] }) => `
    height: 100%;
    min-height: 0;
    flex: 0 0 auto;
    ${/* Needed for the closing sliding effect */ ``}
    will-change: width;
    transition-property: width;
    transition-duration: 225ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    
    & .MuiDrawer-paper {
      width: 100%;
      border: none;
      height: 100%;
      display: grid;
      flex: 1 0 auto;
      overflow: hidden;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
      position: relative !important;

      & > * {
        grid-row: 1;
        grid-column: 1;
      }

      ${
        side === 'left'
          ? `
            border-right: 1px solid ${theme?.palette.divider};
          `
          : `
            border-left: 1px solid ${theme?.palette.divider};
          `
      }
    }

    ${
      open
        ? `
          width: 512px;
        `
        : `
          width: 48px;
          overflow: hidden;
        `
    }
  `
);

export const DrawerContentWrapper = styled(
  Box,
  withoutProps('visible')
)(
  ({ visible }: { visible: boolean }) => `
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1 0 auto;
    overflow: hidden;
    align-items: stretch;
    justify-content: stretch;
    flex-direction: row-reverse;

    will-change: opacity;
    transition-property: opacity;
    transition-duration: 225ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);

    ${
      visible
        ? `
      opacity: 1;
      pointer-events: auto;
    `
        : `
      opacity: 0;
      pointer-events: none;
    `
    }
  `
);

export const DrawerContent = styled(Box)`
  width: 480px;
  height: 100%;
  display: flex;
  flex: 1 0 auto;
  align-items: stretch;
  flex-direction: column;
  justify-content: stretch;
`;

export const DrawerTinyHeader = styled(
  Button,
  withoutProps('visible')
)(
  ({ visible, side }: { visible: boolean; side: DrawerProps['position'] }) => `
    gap: 16px;
    width: 48px;
    min-width: 0;
    align-items: center;
    padding: 16px 0 0 0;
    text-transform: none;
    flex-direction: column;
    justify-content: flex-start;

    will-change: opacity;
    transition-property: opacity;
    transition-duration: 225ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    
    ${
      visible
        ? `
      opacity: 1;
      pointer-events: auto;
    `
        : `
      opacity: 0;
      pointer-events: none;
    `
    }

    ${side === 'left' ? `margin-left: auto;` : `margin-right: auto`}
  `
);

export const RotatedTypography = styled(MuiTypography)`
  align-text: center;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
`;

export const Header = styled(
  Stack,
  withoutProps('position')
)(
  ({ side }: { side: DrawerProps['position'] }) => `
    align-items: center;
    flex-direction: row;

    ${
      side === 'left'
        ? `
        flex-direction: row;
        padding: 16px 24px 8px 24px;
        justify-content: space-between;
    `
        : `
        justify-content: flex-end;
        flex-direction: row-reverse;
        padding: 16px 24px 8px 8px;
    `
    }
  `
);
