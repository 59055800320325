import { z } from 'zod';

export const ApiSourceOfTruthTagsSchema = z.object({
  /**
   * "125"
   */
  tagId: z.string(),
  /**
   * "Google Universal Analytics - Base Tag"
   */
  tagName: z.string(),
  /**
   * "D4T1yr"
   */
  newTagId: z.string().optional(),
});

export type ApiSourceOfTruthTags = z.infer<typeof ApiSourceOfTruthTagsSchema>;
