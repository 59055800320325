import { FeatureFlagDefinition } from '../types/feature';

// ALL ID's ARE AUTOMATICALLY PREFIXED WITH "feature." DURING POST PROCESSING
const defaultFeatureFlags: FeatureFlagDefinition[] = [
  {
    id: 'sotPublish',
    name: 'SOT Publish',
    enabled: false,
    description: "Allows user's to publish SOT configurations to the CDN",
    parent: {
      id: 'role.internalUser',
    },
  },
  {
    id: 'configureConsent',
    name: 'Configure Consent',
    enabled: false,
    description: "Allows user's to configure consent settings for an environment",
    parent: {
      id: 'role.internalUser',
    },
  },
  {
    id: 'dashboard.successAndDisabledBubbles',
    name: 'Api Dashboard - Success & Disabled Bubbles',
    description: 'Shows the success and disabled bubbles on the API dashboard',
    enabled: false,
    parent: {
      id: 'meta.localhost',
    },
  },
  {
    id: 'insights.settingsButton',
    name: 'Insights Page - Settings Button',
    description: 'Shows the settings button on the insights page',
    enabled: false,
    parent: {
      id: 'meta.localhost',
    },
  },
];

export default defaultFeatureFlags;
