import { SupportedOperandsSchema } from 'api/apiTypes';
import { PrivacyConsentRuleIdSchema } from 'api/apiTypes/id/privacy/consentRuleId';
import { PostfixFunctionSchema } from 'api/apiTypes/postfixLogic';
import { z } from 'zod';

/**
 * The base rule object
 * @example
 * {
 *  "ruleId": "id",
 *  "category": "category",
 *  "generatedFunction": {
 *    $function: "function() { return true; }"
 *  }
 * }
 */
export const ApiPrivacyConsentConfigurationMappingsRuleBaseSchema = z.object({
  ruleId: PrivacyConsentRuleIdSchema,
  category: z.string(),
  generatedFunction: PostfixFunctionSchema.optional(),
});

export type ApiPrivacyConsentConfigurationMappingsRuleBase = z.infer<
  typeof ApiPrivacyConsentConfigurationMappingsRuleBaseSchema
>;

/**
 * The default rule object
 * @example
 * {
 *  "ruleId": "id",
 *  "category": "category",
 *  "generatedFunction": {
 *    $function: "function() { return true; }"
 *  }
 * }
 */
export const ApiPrivacyConsentConfigurationMappingsRuleDefaultSchema =
  ApiPrivacyConsentConfigurationMappingsRuleBaseSchema;

export type ApiPrivacyConsentConfigurationMappingsRuleDefault = z.infer<
  typeof ApiPrivacyConsentConfigurationMappingsRuleDefaultSchema
>;

/**
 * The custom rule object
 * @example
 * {
 *  "ruleId": "id",
 *  "name": "name",
 *  "value": "value",
 *  "operator": "operator",
 *  "category": "category",
 *  "generatedFunction": {
 *    $function: "function() { return true; }"
 *  }
 * }
 */
export const ApiPrivacyConsentConfigurationMappingsRuleCustomSchema =
  ApiPrivacyConsentConfigurationMappingsRuleBaseSchema.extend({
    name: z.string(),
    value: z.string(),
    operator: z.union([SupportedOperandsSchema, z.literal('customFunction')]),
  });

export type ApiPrivacyConsentConfigurationMappingsRuleCustom = z.infer<
  typeof ApiPrivacyConsentConfigurationMappingsRuleCustomSchema
>;

/**
 * The rule object
 */
export const ApiPrivacyConsentConfigurationMappingsRuleSchema = z.union([
  ApiPrivacyConsentConfigurationMappingsRuleDefaultSchema,
  ApiPrivacyConsentConfigurationMappingsRuleCustomSchema,
]);

export type ApiPrivacyConsentConfigurationMappingsRule = z.infer<
  typeof ApiPrivacyConsentConfigurationMappingsRuleSchema
>;

/**
 * The base rule object
 * @example
 * {
 *  "name": "name",
 *  "technologyId": "technologyId",
 * }
 */
export const ApiPrivacyConsentConfigurationMappingsBaseSchema = z.object({
  name: z.string(),
  tagId: z.string().nullable(),
});

/**
 * The base rule object
 * @example
 * {
 *   "name": "name",
 *   "technologyId": "technologyId",
 *   "type": "default",
 *   "rules": [
 *     {
 *       "ruleId": "id",
 *       "category": "category",
 *       "generatedFunction": {
 *         $function: "function() { return true; }"
 *       }
 *     }
 *   ]
 * }
 */
export const ApiPrivacyConsentConfigurationMappingsDefaultSchema =
  ApiPrivacyConsentConfigurationMappingsBaseSchema.extend({
    type: z.literal('default'),
    rules: z.array(ApiPrivacyConsentConfigurationMappingsRuleDefaultSchema).length(1),
  });

/**
 * The base rule object
 * @example
 * {
 *   "name": "name",
 *   "technologyId": "technologyId",
 *   "type": "default",
 *   "rules": [
 *     {
 *       "ruleId": "id",
 *       "name": "name",
 *       "value": "value",
 *       "operator": "operator",
 *       "category": "category",
 *       "generatedFunction": {
 *         $function: "function() { return true; }"
 *       }
 *     }
 *   ]
 * }
 */
export const ApiPrivacyConsentConfigurationMappingsCustomSchema =
  ApiPrivacyConsentConfigurationMappingsBaseSchema.extend({
    type: z.literal('custom'),
    rules: z.array(ApiPrivacyConsentConfigurationMappingsRuleCustomSchema),
  });

/**
 * The mappings object
 */
export const ApiPrivacyConsentConfigurationMappingsSchema = z.union([
  ApiPrivacyConsentConfigurationMappingsDefaultSchema.deepPartial(),
  ApiPrivacyConsentConfigurationMappingsCustomSchema.deepPartial(),
]);

type ApiPrivacyConsentConfigurationMappings = z.infer<typeof ApiPrivacyConsentConfigurationMappingsSchema>;

export default ApiPrivacyConsentConfigurationMappings;
