import { EnvironmentId } from 'api/apiTypes';
import useLogs from 'api/logs';
import routes from 'api/routes';
import ApiError from 'api/types/base/apiError';
import ApiLoading from 'api/types/base/apiLoading';
import ReportId from 'api/types/report/id';
import { ReportContext } from 'api/useReport';
import useReports from 'api/useReports';
import { error, isErrored, isLoading, loading, useExperimentalContext, withoutError } from 'api/utils';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { ZodError } from 'zod';

import { useAxios } from 'utils/transport/useAxios';

import ApiPrivacyResponse, { ApiPrivacy, ApiPrivacyResponseError, ApiPrivacyResponseSuccessSchema } from './types/api';
import ApiPrivacyConsentConfiguration, { ApiPrivacyConsentConfigurationSchema } from './types/api/consentConfiguration';
import PrivacyContextType from './types/context';

const PrivacyContext = React.createContext({} as PrivacyContextType);

export function PrivacyProvider({ children }: React.PropsWithChildren) {
  const { get } = useAxios();

  const { log, logObject } = useLogs();
  const [privacyCache, setPrivacyCache] = React.useState<PrivacyContextType['privacyCache']>({});

  const reportIds = useRef(new Set<string>());

  const getPrivacy: PrivacyContextType['getPrivacy'] = (report) => {
    if (isLoading(report) || isErrored(report) || reportIds.current.has(report.id)) return;
    reportIds.current.add(report.id);
    const envGuid = report.summary.json.environmentId;
    const dbGuid = report.summary.json.clientGuid;
    get<ApiPrivacyResponse | ApiPrivacyResponseError>(routes.getPrivacyConfig(envGuid, dbGuid), {
      withCredentials: true,
    })
      .then(({ data }) => {
        try {
          const r = ApiPrivacyResponseSuccessSchema.parse(data);
          setPrivacyCache((prev) => ({ ...prev, [report.id]: r.data }));
        } catch (_e: unknown) {
          const e = _e as ZodError;
          if (e.errors && e.errors.length) {
            log(`[ZOD] error: report => MALFORMED DATA`, 'Error');
            logObject(`[ZOD] errorsof: report`, e.errors);
            setPrivacyCache((prev) => ({ ...prev, [report.id]: error(e.errors.map((x) => x.message)) }));
          }
        }
      })
      .catch((e: Error) => {
        setPrivacyCache((prev) => ({ ...prev, [report.id]: error([e.message]) }));
      });
  };

  const value = {
    reportIds,
    getPrivacy,
    privacyCache,
  };

  return <PrivacyContext.Provider value={value}>{children}</PrivacyContext.Provider>;
}

export default function usePrivacy(reportId: ReportId): ApiPrivacy | ApiError | ApiLoading {
  const reports = useReports();
  const reportContext = useContext(ReportContext);
  const _privacyContext = useExperimentalContext(PrivacyContext);
  const privacyContext = withoutError(_privacyContext);

  useEffect(() => {
    // First try to find the current report from the profile endpoint payload
    if (reportId) {
      const reportDetails = reports.find((x) => x.id === reportId);
      if (reportDetails) {
        // next try to extract dbguid and envguid from it
        if (reportDetails.envGuid && reportDetails.dbGuid) {
          // eslint-disable-next-line no-console
          console.log('[PrivacyProvider] Using profile endpoint instead of downloading the report json');
          privacyContext?.getPrivacy({
            id: reportId,
            summary: {
              json: {
                environmentId: reportDetails.envGuid,
                clientGuid: reportDetails.dbGuid,
              },
            },
          });
        } else {
          // eslint-disable-next-line no-console
          console.log('[PrivacyProvider] Profile endpoint issue, downloading report json instead');
          // if not found, then fallback to downloading the report json.
          const cachedReport = reportContext.reportCache[reportId];
          if (!isLoading(cachedReport) && !isErrored(cachedReport)) {
            privacyContext?.getPrivacy(reportContext.reportCache[reportId]);
          } else {
            reportContext.getReport(reportId);
          }
        }
      } else {
        if (privacyContext) privacyContext.privacyCache[reportId] = error();
      }
    }
  }, [reportId, privacyContext, reports, reportContext]);

  if (isErrored(_privacyContext)) return _privacyContext;

  const privacyResponse = privacyContext?.privacyCache[reportId];

  if (isLoading(privacyResponse)) return loading();
  if (isErrored(privacyResponse)) return error(privacyResponse.messages);

  return privacyResponse;
}

export function useSavePrivacyConsent(envGuid: EnvironmentId | null, dbGuid: string | null) {
  const { put } = useAxios();

  const savePrivacyConsent = useCallback(
    (data: ApiPrivacyConsentConfiguration) => {
      if (!dbGuid) return error(['DB GUID not found']);
      if (!envGuid) return error(['Environment GUID not found']);
      const z = ApiPrivacyConsentConfigurationSchema.parse(data);
      z.confirmed = true;

      return put(routes.savePrivacyConsent(envGuid, dbGuid), z, { withCredentials: true });
    },
    [dbGuid, envGuid, put]
  );

  return savePrivacyConsent;
}

export function useStalePrivacy(reportId: ReportId, envGuid: EnvironmentId | null, dbGuid: string | null) {
  const _privacyContext = useExperimentalContext(PrivacyContext);
  const privacyContext = withoutError(_privacyContext);

  const savePrivacyConsent = useCallback(() => {
    if (!dbGuid) return error(['DB GUID not found']);
    if (!envGuid) return error(['Environment GUID not found']);
    privacyContext?.reportIds.current.delete(reportId);
    privacyContext?.getPrivacy({
      id: reportId,
      summary: {
        json: {
          environmentId: envGuid,
          clientGuid: dbGuid,
        },
      },
    });
  }, [dbGuid, envGuid, privacyContext, reportId]);

  if (isErrored(_privacyContext)) return _privacyContext;

  return savePrivacyConsent;
}
