import { EnvironmentId, TagId } from 'api/apiTypes';
import Notification, {
  NotificationSchema,
  NotificationWithId,
  NotificationWithIdSchema,
  NotificationWithoutEnvGuidWithIdSchema,
} from 'api/apiTypes/notification';
import {
  ApiNextNotificationGroupPaths,
  ApiNextNotificationGroupPathsSchema,
} from 'api/apiTypes/notification/groupPaths';
import routes from 'api/routes';
import ApiError from 'api/types/base/apiError';
import ApiLoading from 'api/types/base/apiLoading';
import { error, isErrored, isLoading, loading, withoutError } from 'api/utils';
import React, { createContext, useCallback, useContext, useRef, useState } from 'react';
import useSWR, { SWRResponse } from 'swr';
import { z } from 'zod';

import { useAxios } from 'utils/transport/useAxios';

import NotificationId from './types/id';
import NotificationContextType from './types/provider';

export const NotificationContext = createContext({} as NotificationContextType);

export function NotificationsProvider(props: React.PropsWithChildren) {
  const { get, post, delete: reqDelete, put } = useAxios();
  const [hasConsumer, setHasConsumer] = useState(false);
  const [localStore, setLocalStore] = useState<NotificationContextType['notifications']>({});
  const [groupPaths, setGroupPaths] = useState<NotificationContextType['groupPaths']>({});
  const notificationsStore = useRef(new Set<EnvironmentId>());

  const getNotifications = useCallback(
    async (envGuid: EnvironmentId) => {
      if (notificationsStore.current.has(envGuid)) return;
      notificationsStore.current.add(envGuid);

      // get notifications from api
      const url = routes.notifications.get(envGuid);

      const response = await get(url, { withCredentials: true });

      try {
        const notifications = NotificationWithIdSchema.array().parse(response.data);

        setLocalStore((prev) => ({
          ...prev,
          [envGuid]: notifications,
        }));
      } catch (e: any) {
        setLocalStore((prev) => ({
          ...prev,
          [envGuid]: error(['Failed to load notifications', e.toString()]),
        }));
      }
    },
    [get]
  );

  const createNotification = useCallback(
    async (notification: Notification, envGuid: EnvironmentId) => {
      const payload = NotificationSchema.parse(notification);
      const response = await post(routes.notifications.create(), payload, { withCredentials: true });

      const createdNotification = NotificationWithoutEnvGuidWithIdSchema.parse(response.data);
      setLocalStore((prev) => ({
        ...prev,
        [envGuid]: [...(withoutError(prev[envGuid]) ?? []), createdNotification],
      }));
    },
    [post]
  );

  const updateNotification = useCallback(
    async (oldNotification: NotificationWithId, envGuid: EnvironmentId) => {
      const payload = NotificationSchema.parse(oldNotification);

      const response = await put(routes.notifications.update(oldNotification._id), payload, { withCredentials: true });

      const updatedNotification = NotificationWithoutEnvGuidWithIdSchema.parse(response.data);
      setLocalStore((prev) => ({
        ...prev,
        [envGuid]: (withoutError(prev[envGuid]) ?? []).map((notification) =>
          notification._id === oldNotification._id
            ? { ...updatedNotification, envGuid: notification.envGuid }
            : notification
        ),
      }));
    },
    [put]
  );

  const deleteNotification = useCallback(
    async (id: NotificationId, envGuid: EnvironmentId) => {
      const response = await reqDelete(routes.notifications.delete(id), { withCredentials: true });

      const deletedNotification = NotificationWithoutEnvGuidWithIdSchema.parse(response.data);

      setLocalStore((prev) => ({
        ...prev,
        [envGuid]: (withoutError(prev[envGuid]) ?? []).filter(
          (notification) => notification._id !== deletedNotification._id
        ),
      }));
    },
    [reqDelete]
  );

  const getGroupPaths = useCallback(
    async (envGuid: EnvironmentId, tagId: TagId) => {
      if (!envGuid || !tagId) return;
      const key: `${EnvironmentId}-${TagId}` = `${envGuid}-${tagId}`;
      if (!!groupPaths[key]) return;
      setGroupPaths((prev) => ({ ...prev, [key]: [] }));

      // get notifications from api
      const url = routes.notifications.groupPaths.get(envGuid, tagId);

      const response = await get(url, { withCredentials: true });

      try {
        const rawSchema = z.record(
          z.object({
            friendlyName: z.string(),
            technicalName: z.string(),
          })
        );

        const rawNotifications = rawSchema.parse(response.data);
        const _notifications = Object.entries(rawNotifications).map(([groupPath, data]) => ({
          ...data,
          groupPath,
        }));
        const notifications = ApiNextNotificationGroupPathsSchema.array().parse(_notifications);

        setGroupPaths((prev) => ({
          ...prev,
          [key]: notifications,
        }));
      } catch (e: any) {
        setGroupPaths((prev) => ({
          ...prev,
          [key]: error(['Failed to load group paths', e.toString()]),
        }));
      }
    },
    [get, groupPaths]
  );

  const value: NotificationContextType = {
    groupPaths,
    hasConsumer,
    getGroupPaths,
    setHasConsumer,
    getNotifications,
    createNotification,
    updateNotification,
    deleteNotification,
    notifications: localStore,
    setNotifications: setLocalStore,
  };

  return <NotificationContext.Provider value={value}>{props.children}</NotificationContext.Provider>;
}

export default function useNotifications(envGuid: EnvironmentId | null): NotificationWithId[] | ApiError | ApiLoading {
  const notificationsContext = useContext(NotificationContext);

  if (!envGuid) return [];

  const notifications = notificationsContext.notifications[envGuid];

  // isLoading is basically a check for whether it is "undefined" or not.
  if (isLoading(notifications)) {
    notificationsContext.getNotifications(envGuid);
  }

  return notifications;
}

export function useCreateNotification(envGuid: EnvironmentId | null): ((notification: Notification) => void) | null {
  const notificationsContext = useContext(NotificationContext);

  if (!envGuid) return null;

  return (notification: Notification) => notificationsContext.createNotification(notification, envGuid);
}

export function useDeleteNotification(envGuid: EnvironmentId | null): ((id: NotificationId) => void) | null {
  const notificationsContext = useContext(NotificationContext);

  if (!envGuid) return null;

  return (id: NotificationId) => notificationsContext.deleteNotification(id, envGuid);
}

export function useUpdateNotification(
  envGuid: EnvironmentId | null
): ((notification: NotificationWithId) => void) | null {
  const notificationsContext = useContext(NotificationContext);

  if (!envGuid) return null;

  return (notification: NotificationWithId) => notificationsContext.updateNotification(notification, envGuid);
}

export function useNotificationsGroupPaths(
  envGuid: EnvironmentId | null,
  tagId: TagId
): ApiNextNotificationGroupPaths[] | ApiError | ApiLoading {
  const notificationsContext = useContext(NotificationContext);

  if (!envGuid) return [];

  const key: `${EnvironmentId}-${TagId}` = `${envGuid}-${tagId}`;
  const groupPaths = notificationsContext.groupPaths[key];

  // isLoading is basically a check for whether it is "undefined" or not.
  if (isLoading(groupPaths)) {
    notificationsContext.getGroupPaths(envGuid, tagId);
    return loading();
  }

  if (isErrored(groupPaths)) {
    return groupPaths;
  }

  return groupPaths;
}

export function usePIIRules(envGuid: EnvironmentId | null): SWRResponse<string[]> {
  const { get } = useAxios();

  return useSWR(envGuid && routes.notifications.piiRules.get(envGuid), async (url) => {
    return z.array(z.string()).parse(
      (
        await get(url, {
          withCredentials: true,
        })
      ).data
    );
  });
}
