// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import { z } from 'zod';

import { EnvironmentIdSchema } from '../id';

export const ApiSourceOfTruthPublishRequestSchema = z.object({
  pageUrlFilterPattern: z.string().optional(), // Admin
  environmentGuid: EnvironmentIdSchema,
  publishGlobalRulesJsFile: z.boolean(), // Anyone
  publishPageRulesJsFiles: z.boolean(), // Anyone
  updateSotRulesInGlobalJs: z.boolean(), // Anyone
  updateConsentConfigurationInGlobalJs: z.boolean(), // Anyone
  updateClientConfigInGlobalJs: z.boolean(), // Admin
  publishTestFiles: z.boolean().optional(), // Anyone
  includeAutoGeneratedRules: z.boolean().optional(), // Admin
});

export type ApiSourceOfTruthPublishRequest = z.infer<typeof ApiSourceOfTruthPublishRequestSchema>;

export const ApiSourceOfTruthPublishResponseSchema = z
  .object({
    areGlobalRulesPublished: z.boolean().optional(),
    publishedPageRulesCount: z.number().optional(),
    totalPageRulesCount: z.number().optional(),
    input: ApiSourceOfTruthPublishRequestSchema,
    status: z.union([z.literal('INITIATED'), z.literal('COMPLETED'), z.literal('FAILED')]),
    initializeTimeStamp: z.string(),
  })
  .transform((data) => {
    const date = moment(data.initializeTimeStamp);

    const finalObj = {
      date,
      isFailed: false,
      isPending: false,
      inProgress: false,
      isComplete: false,
      page: {
        available: false,
        progress: 0,
        progressCurrent: 0,
        progressTotal: 0,
        isComplete: false,
        inProgress: false,
        isPending: false, // pending is based off wether or not the pageVariables are undefined
      },
      global: {
        available: false,
        isComplete: false,
        inProgress: false,
        isPending: false, // same as above
      },
    };

    // fill from datar
    finalObj.page.available = data.input.publishPageRulesJsFiles;
    finalObj.global.available = data.input.publishGlobalRulesJsFile;

    switch (data.status) {
      case 'INITIATED':
        if (finalObj.page.available) {
          if (data.publishedPageRulesCount !== undefined && data.totalPageRulesCount !== undefined) {
            finalObj.page.progressTotal = data.totalPageRulesCount;
            finalObj.page.progressCurrent = data.publishedPageRulesCount;

            if (finalObj.page.progressCurrent >= finalObj.page.progressTotal) {
              finalObj.page.progress = 1;
              finalObj.page.isComplete = true;
            } else {
              finalObj.page.inProgress = true;
              finalObj.page.progress = finalObj.page.progressCurrent / finalObj.page.progressTotal;
            }
          } else {
            finalObj.page.isPending = true;
          }
        }

        if (finalObj.global.available) {
          if (data.areGlobalRulesPublished !== undefined) {
            if (data.areGlobalRulesPublished) {
              finalObj.global.isComplete = true;
            } else {
              finalObj.global.inProgress = true;
            }
          } else {
            finalObj.global.isPending = true;
          }
        }

        if (
          finalObj.page.inProgress ||
          finalObj.page.isComplete ||
          finalObj.global.inProgress ||
          finalObj.global.isComplete
        ) {
          finalObj.inProgress = true;
        } else {
          finalObj.isPending = true;
        }
        break;
      case 'COMPLETED':
        finalObj.isComplete = true;
        if (finalObj.page.available) finalObj.page.isComplete = true;
        if (finalObj.global.available) finalObj.global.isComplete = true;
        break;
      case 'FAILED':
        finalObj.isFailed = true;
        break;
    }

    return finalObj;
  });

export type ApiSourceOfTruthPublishResponse = z.infer<typeof ApiSourceOfTruthPublishResponseSchema>;
