import {
  ApiSourceOfTruth,
  ApiSourceOfTruthCustomizationCustomRule,
  ApiSourceOfTruthCustomizationCustomRuleSchema,
  ApiSourceOfTruthRuleSchema,
  ApiSourceOfTruthSchema,
  ApiSourceOfTruthTags,
  ApiSourceOfTruthTagsSchema,
  EnvironmentId,
  ReportId,
  TagId,
} from 'api/apiTypes';
import { ApiNotificationGroupPaths, ApiNotificationGroupPathsSchema } from 'api/apiTypes/notification/groupPaths';
import { ApiSourceOfTruthPageFilter } from 'api/apiTypes/sourceOfTruth/pageFilters';
import { ApiSourceOfTruthPublishResponseSchema } from 'api/apiTypes/sourceOfTruth/publish';
import { DateRange } from 'api/date';
import routes from 'api/routes';
import useReports from 'api/useReports';
import { Moment } from 'moment';
import { useCallback, useMemo } from 'react';
import useSWR, { SWRResponse } from 'swr';
import { z } from 'zod';

import { useAxios } from 'utils/transport/useAxios';

export function useNextGlobalSourceOfTruth(envGuid: EnvironmentId | null) {
  const { get } = useAxios();

  return async (tagId: TagId, _page: number, _limit: number, date?: DateRange): Promise<ApiSourceOfTruth> => {
    if (!date?.fromDate || !date.toDate) throw new Error('Missing date');
    const url = routes.sourceOfTruth.getGlobalSourceOfTruth(
      envGuid ?? ('' as EnvironmentId),
      tagId,
      date?.fromDate,
      date?.toDate
    );
    if (!envGuid || !tagId || !date) throw new Error('Missing report or tagId or date');
    const response = await get(url, {
      withCredentials: true,
    });
    const data = ApiSourceOfTruthRuleSchema.array().parse(response.data);
    const newSotData = {
      rules: data.map((x) => ({
        ...x,
        groupPathFriendly: `${x.friendlyName} (${x.groupPath})`,
      })),
    };
    return newSotData;
  };
}

export function useNextPageSourceOfTruth(envGuid: EnvironmentId | null) {
  const { post } = useAxios();

  return async (
    tagId: TagId,
    filters: ApiSourceOfTruthPageFilter[],
    page: number,
    limit: number,
    date?: DateRange
  ): Promise<ApiSourceOfTruth> => {
    if (!date?.fromDate || !date.toDate) throw new Error('Missing date');
    const url = routes.sourceOfTruth.getPageSourceOfTruth(
      envGuid ?? ('' as EnvironmentId),
      tagId,
      page,
      limit,
      undefined,
      date.fromDate,
      date.toDate
    );
    if (!envGuid || !tagId || !date || !filters || !filters.length) throw new Error('Missing report or tagId or date');
    return ApiSourceOfTruthSchema.parse(
      (
        await post(
          url,
          {
            filters,
          },
          {
            withCredentials: true,
          }
        )
      ).data
    );
  };
}

export function useNextTagIds(envGuid: EnvironmentId | null, date?: DateRange): SWRResponse<ApiSourceOfTruthTags[]> {
  const { get } = useAxios();

  const hook = useSWR(
    envGuid && date?.fromDate && date.toDate
      ? routes.sourceOfTruth.getTagIds(envGuid, date.fromDate, date.toDate)
      : null,
    async (url) => {
      // eslint-disable-next-line sentinelinsights/no-mutations
      return ApiSourceOfTruthTagsSchema.array()
        .parse(
          (
            await get(url, {
              withCredentials: true,
            })
          ).data
        )
        .sort((a, b) => a.tagName.localeCompare(b.tagName));
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return hook;
}

const ApiSourceOfTruthCustomizationsByTagResponseSchema = z.object({
  tagId: z.string(),
  friendlyName: z.string(),
  sotRules: z.array(z.any()),
});

type ApiSourceOfTruthCustomizationsByTag = {
  tagId: string;
  friendlyName: string;
  sotRules: ApiSourceOfTruthCustomizationCustomRule[];
};

export function useSotCustomizations(
  envGuid: EnvironmentId | null
): SWRResponse<ApiSourceOfTruthCustomizationsByTag[]> {
  const { get } = useAxios();

  const hook = useSWR(
    envGuid ? routes.sourceOfTruth.customizations.getAll(envGuid) : null,
    async (url) => {
      const response = await get(url, {
        withCredentials: true,
      });

      const unverifiedData = ApiSourceOfTruthCustomizationsByTagResponseSchema.array().parse(response.data);

      const verifiedData: ApiSourceOfTruthCustomizationsByTag[] = [];

      for (const entry of unverifiedData) {
        const newSotRules: ApiSourceOfTruthCustomizationCustomRule[] = [];
        for (const rule of entry.sotRules) {
          const verifiedRule = ApiSourceOfTruthCustomizationCustomRuleSchema.parse(rule);
          newSotRules.push(verifiedRule);
        }

        verifiedData.push({
          tagId: entry.tagId,
          friendlyName: entry.friendlyName,
          sotRules: newSotRules,
        });
      }

      return verifiedData;
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return hook;
}

export function useNextGroupPaths(envGuid: EnvironmentId | null) {
  const { get } = useAxios();

  return useCallback(
    async (tagId: TagId, date?: Moment): Promise<ApiNotificationGroupPaths[]> => {
      const url = routes.notifications.groupPaths.get(envGuid ?? ('' as EnvironmentId), tagId, date);

      if (!envGuid || !date) return [];
      return ApiNotificationGroupPathsSchema.array().parse(
        (
          await get(url, {
            withCredentials: true,
          })
        ).data
      );
    },
    [envGuid, get]
  );
}

export function useNextPageUrls(envGuid: EnvironmentId | null) {
  const { get } = useAxios();

  return async (tagId: TagId, fromDate: Moment, toDate: Moment, search: string) => {
    const url = routes.sourceOfTruth.getPageUrls(envGuid ?? ('' as EnvironmentId), tagId, fromDate, toDate, search);

    if (!envGuid || !tagId || !fromDate || !toDate) return [];
    return z
      .string()
      .array()
      .parse(
        (
          await get(url, {
            withCredentials: true,
          })
        ).data
      );
  };
}

export function useSOTPublishProgress(reportId: ReportId, pastDay?: boolean) {
  const { get } = useAxios();
  const reports = useReports();

  const currentReport = useMemo(() => reports.find((r) => r.id === reportId), [reports, reportId]);

  return useSWR(
    currentReport?.envGuid
      ? routes.sourceOfTruth.publish.getProgress(currentReport?.envGuid as EnvironmentId, pastDay)
      : null,
    async (url) => {
      const response = await get(url, {
        withCredentials: true,
      }).catch(() => null);

      return ApiSourceOfTruthPublishResponseSchema.parse(response?.data);
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryCount: 0,
    }
  );
}
