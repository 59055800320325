import { MiddlewareError, MiddlewareEvent } from 'api/events';
import { ClickHouseDataHeadingType, ClickHouseParseDataHeadingType } from 'api/utils/clickhouse';
import LzString from 'lz-string';
import moment, { Moment } from 'moment';
import { z } from 'zod';

import {
  DashboardInsightsLeftNavHeaderKeys,
  DashboardInsightsModel,
  DashboardInsightsModelSchema,
  leftNavId,
} from './leftnav.model';

// ===============
// = UI View Models
// ===============

// Individual Insights
const DashboardInsightsLeftNavViewModelSchema = z.object({
  id: z.string(),
  // insightId: z.string(),
  title: z.string(),
  time: z.custom<Moment>((data) => moment.isMoment(data)),
  category: z.string(),
  riskLevel: z.enum(['passed', 'warning', 'critical', 'disabled']),
  vendor: z.string(),
  description: z.string(),
  recommendation: z.string(),
  businessImpact: z.string(),
  // pulled from query variables
  tagId: z.string().optional(),
  groupPath: z.string().optional(),
  tableMessage: z.union([z.string(), z.null()]),
  allowDataExport: z.boolean().default(false),
  violatedBestPracticeId: z.string().optional(),
});
export type DashboardInsightsLeftNavViewModel = z.infer<typeof DashboardInsightsLeftNavViewModelSchema>;
export type ApiDashboardReport = DashboardInsightsLeftNavViewModel;

// Vendors
export const DashboardInsightsVendorViewModelSchema = z.object({
  vendor: z.string(),
  insights: z.array(DashboardInsightsLeftNavViewModelSchema).default([]),
  success: z.array(DashboardInsightsLeftNavViewModelSchema).default([]),
  warning: z.array(DashboardInsightsLeftNavViewModelSchema).default([]),
  critical: z.array(DashboardInsightsLeftNavViewModelSchema).default([]),
  disabled: z.array(DashboardInsightsLeftNavViewModelSchema).default([]),
});
export type DashboardInsightsVendorViewModel = z.infer<typeof DashboardInsightsVendorViewModelSchema>;

// Root object
export const DashboardInsightsViewModelSchema = z.object({
  leftNav: z.array(DashboardInsightsLeftNavViewModelSchema).default([]),
  success: z.array(DashboardInsightsLeftNavViewModelSchema).default([]),
  warning: z.array(DashboardInsightsLeftNavViewModelSchema).default([]),
  critical: z.array(DashboardInsightsLeftNavViewModelSchema).default([]),
  disabled: z.array(DashboardInsightsLeftNavViewModelSchema).default([]),
  vendors: z.array(DashboardInsightsVendorViewModelSchema).default([]),
});
export type DashboardInsightsViewModel = z.infer<typeof DashboardInsightsViewModelSchema>;

// ==================================
// = Model to View Model Converters =
// ==================================

export function dashboardInsightsModelToViewModel(input: unknown, parentEvent?: MiddlewareEvent) {
  const validationEvent = new MiddlewareEvent({
    namespace: 'Dashboard',
    source: 'validate',
    name: 'Convert Model to ViewModel',
    path: `dashboardDetailsModelToViewModel()`,
    data: input,
    parent: parentEvent?.id, // to record the init AND the update event
  });

  let model: DashboardInsightsModel;

  try {
    model = DashboardInsightsModelSchema.parse(input);

    validationEvent.status = 'completed';
    validationEvent.resultText = JSON.stringify(model);
  } catch (error) {
    validationEvent.status = 'failed';
    validationEvent.resultText = `${error}`;

    const middlewareError = new MiddlewareError({
      cause: error instanceof Error ? error : new Error(`${error}`),
      event: validationEvent,
      viewType: 'data',
    });

    throw middlewareError;
  }

  // TODO: since this is the "generic" model that includes the leftNav along with other data,
  // we should probably split this into separate functions for the different parts of the model
  // i.e. dont fail on just the leftNav being missing in the future.
  if (!model[leftNavId]) {
    const blankViewModel: DashboardInsightsViewModel = {
      critical: [],
      leftNav: [],
      success: [],
      vendors: [],
      warning: [],
      disabled: [],
    };

    return blankViewModel;
  }

  const headers: Record<DashboardInsightsLeftNavHeaderKeys, { index: number; type: ClickHouseDataHeadingType } | null> =
    {
      insight: null,
      last_seen: null,
      query_variables: null,
      title: null,
      category_friendly_name: null,
      report_summary_name: null,
      risk_level: null,
      business_impact: null,
      description: null,
      recommendation: null,
      table_message: null,
      allow_data_export: null,
    };

  model[leftNavId]?.meta?.forEach((header, i) => {
    headers[header.name] = {
      index: i,
      type: ClickHouseParseDataHeadingType(header.type),
    };
  });

  try {
    if (headers.title === null) throw new Error('Mismatched headers');
    if (headers.insight === null) throw new Error('Mismatched headers');
    if (headers.last_seen === null) throw new Error('Mismatched headers');
    if (headers.query_variables === null) throw new Error('Mismatched headers');
    if (headers.category_friendly_name === null) throw new Error('Mismatched headers');
    if (headers.report_summary_name === null) throw new Error('Mismatched headers');
    if (headers.risk_level === null) throw new Error('Mismatched headers');
    if (headers.business_impact === null) throw new Error('Mismatched headers');
    if (headers.description === null) throw new Error('Mismatched headers');
    if (headers.recommendation === null) throw new Error('Mismatched headers');

    validationEvent.status = 'completed';
    validationEvent.resultText = JSON.stringify(model);
  } catch (error) {
    validationEvent.status = 'failed';
    validationEvent.resultText = `${error}`;

    const middlewareError = new MiddlewareError({
      cause: error instanceof Error ? error : new Error(`${error}`),
      event: validationEvent,
      viewType: 'data',
    });

    throw middlewareError;
  }

  const data: DashboardInsightsLeftNavViewModel[] = [];
  const dataSuccess: DashboardInsightsLeftNavViewModel[] = [];
  const dataWarning: DashboardInsightsLeftNavViewModel[] = [];
  const dataCritical: DashboardInsightsLeftNavViewModel[] = [];
  const dataDisabled: DashboardInsightsLeftNavViewModel[] = [];

  const vendors: Record<string, Omit<DashboardInsightsVendorViewModel, 'vendor'>> = {};

  for (const row of model[leftNavId]?.data ?? []) {
    const model: Record<DashboardInsightsLeftNavHeaderKeys, unknown> = {
      title: row[headers.title.index],
      insight: row[headers.insight.index],
      last_seen: row[headers.last_seen.index],
      query_variables: row[headers.query_variables.index],
      category_friendly_name: row[headers.category_friendly_name.index],
      report_summary_name: row[headers.report_summary_name.index],
      risk_level: row[headers.risk_level.index],
      business_impact: row[headers.business_impact.index],
      description: row[headers.description.index],
      recommendation: row[headers.recommendation.index],
      table_message: headers.table_message ? row[headers.table_message.index] : null,
      allow_data_export: headers.allow_data_export ? row[headers.allow_data_export.index] : false,
    };

    if (
      typeof model.title !== 'string' ||
      typeof model.insight !== 'string' ||
      typeof model.last_seen !== 'string' ||
      typeof model.category_friendly_name !== 'string' ||
      typeof model.report_summary_name !== 'string' ||
      typeof model.risk_level !== 'string' ||
      typeof model.query_variables !== 'object' ||
      typeof model.business_impact !== 'string' ||
      typeof model.description !== 'string' ||
      typeof model.recommendation !== 'string' ||
      (typeof model.table_message !== 'string' && model.table_message !== null) ||
      model.query_variables === null
    )
      continue;

    try {
      const timezone = headers.last_seen.type.args[0]?.type ?? 'UTC';
      const viewModel: DashboardInsightsLeftNavViewModel = {
        title: model.title,
        description: model.description,
        businessImpact: model.business_impact,
        recommendation: model.recommendation,
        tableMessage: model.table_message,
        allowDataExport: Boolean(model.allow_data_export),
        id: decodeURIComponent(
          LzString.compressToEncodedURIComponent(JSON.stringify({ insight: model.insight, ...model.query_variables }))
        ).replaceAll('+', ' '),
        time: moment.tz(model.last_seen, timezone).local().subtract(1, 'second'),
        category: model.category_friendly_name,
        riskLevel:
          model.risk_level === 'warning'
            ? 'warning'
            : model.risk_level === 'critical'
            ? 'critical'
            : model.risk_level === 'disabled'
            ? 'disabled'
            : 'passed',

        vendor: model.report_summary_name,
        tagId:
          'tag_id' in model.query_variables && typeof model.query_variables?.tag_id === 'string'
            ? model.query_variables.tag_id
            : undefined,
        groupPath:
          'variable_group_path' in model.query_variables &&
          typeof model.query_variables?.variable_group_path === 'string'
            ? model.query_variables.variable_group_path
            : undefined,
        violatedBestPracticeId:
          'violated_best_practice_id' in model.query_variables &&
          typeof model.query_variables?.violated_best_practice_id === 'string'
            ? model.query_variables.violated_best_practice_id
            : undefined,

        // tagId:
        //   'tag_id' in model.query_variables && typeof model.query_variables?.tag_id === 'string'
        //     ? model.query_variables.tag_id
        //     : undefined,
      };

      vendors[model.report_summary_name] = vendors[model.report_summary_name] ?? {
        insights: [],
        success: [],
        warning: [],
        critical: [],
      };

      data.push(viewModel);
      vendors[model.report_summary_name].insights.push(viewModel);

      switch (viewModel.riskLevel) {
        case 'warning':
          dataWarning.push(viewModel);
          vendors[model.report_summary_name].warning.push(viewModel);
          break;
        case 'critical':
          dataCritical.push(viewModel);
          vendors[model.report_summary_name].critical.push(viewModel);
          break;
        case 'passed':
          dataSuccess.push(viewModel);
          vendors[model.report_summary_name].success.push(viewModel);
          break;
        case 'disabled':
          dataDisabled.push(viewModel);
          vendors[model.report_summary_name].disabled.push(viewModel);
          break;
      }
    } catch {}
  }

  const viewModel: DashboardInsightsViewModel = {
    // eslint-disable-next-line sentinelinsights/no-mutations
    leftNav: data.sort((a, b) => a.title.localeCompare(b.title)),
    // eslint-disable-next-line sentinelinsights/no-mutations
    success: dataSuccess.sort((a, b) => a.title.localeCompare(b.title)),
    // eslint-disable-next-line sentinelinsights/no-mutations
    warning: dataWarning.sort((a, b) => a.title.localeCompare(b.title)),
    // eslint-disable-next-line sentinelinsights/no-mutations
    critical: dataCritical.sort((a, b) => a.title.localeCompare(b.title)),
    // eslint-disable-next-line sentinelinsights/no-mutations
    disabled: dataDisabled.sort((a, b) => a.title.localeCompare(b.title)),
    vendors: Object.entries(vendors).map(([vendor, insights]) => ({ vendor, ...insights })),
  };

  return viewModel;
}
