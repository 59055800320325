import { EnvironmentId } from 'api/apiTypes';
import { CurrentUserContext } from 'api/useCurrentUser';
import { useContext, useMemo } from 'react';

import { useReportId } from './useReportId';

export function useReportGuids() {
  const context = useContext(CurrentUserContext);
  const reportId = useReportId();

  const guids = useMemo(() => context.reports.find((x) => x.id === reportId), [context.reports, reportId]);

  return {
    reportGuid: reportId,
    envGuid: (guids?.envGuid as EnvironmentId) || null,
    dbGuid: guids?.dbGuid || null,
  };
}
