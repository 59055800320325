import { MetaFlagDefinition } from '../types/meta';

// ALL ID's ARE AUTOMATICALLY PREFIXED WITH "meta." DURING POST PROCESSING
const defaultMetaFlags: MetaFlagDefinition[] = [
  {
    id: 'localhost',
    name: 'Localhost',
    description: 'This flag is enabled when the app is running on localhost',
    enabled: false,
  },
];

export default defaultMetaFlags;
