import { LicenseInfo } from '@mui/x-license-pro';
import { ExperimentsProvider, globalExperimentsStore } from 'api/experiments';
import FeatureFlags from 'api/feature-flags';
import moment from 'moment-timezone';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';

moment.tz.setDefault(moment.tz.guess());

if (globalExperimentsStore['flag.isOldWebsite'])
  window.location = window.location.toString().replace('datasentinelai.com', 'sentinelinsights.com') as any;

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY ?? '');
FeatureFlags.init();

if (window.location.hostname === 'localhost') {
  FeatureFlags.updateFlag('meta.localhost', {
    source: 'custom',
    value: true,
  });
}

/**
 * All required objects and their fields with description are listed here. You can check detailed summary of them here.
 * @typedef {Module}
 * @namespace TypeDefinitions
 */

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <ExperimentsProvider>
    <App />
  </ExperimentsProvider>
);

reportWebVitals();
