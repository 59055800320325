import { useCurrentUser, useReportId, useReports } from 'api';
import { PartialReport } from 'api/apiTypes';
import type ReportId from 'api/types/report/id';
import { isEnvironmentFavorited, isErrored, withoutError } from 'api/utils';
import { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useEnvironmentSelector() {
  const _userProfile = useCurrentUser();
  const userProfile = withoutError(_userProfile);
  const _reports = useReports();
  const [, setSearchParams] = useSearchParams();

  const reportId = useReportId();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [_counter, _refresh] = useState(0);
  const open = !!anchorEl;

  const refreshReports = useCallback(() => _refresh((x) => x + 1), []);

  const onClose = () => setAnchorEl(null);
  const onClick: MouseEventHandler<HTMLElement> = (e) => setAnchorEl(e.currentTarget);

  //eslint-disable-next-line
  const onReportClick = useCallback((report: { id: ReportId }) => {
      onClose();
      setSearchParams({
        reportId: report.id,
      });
      // reportParams.setId(report.id);
    },
    [setSearchParams]
  );

  const ready = !!userProfile || isErrored(_userProfile);

  // const infoIcon: InfoIcon =
  //   report === error()
  //     ? {
  //         icon: <ErrorOutline color="error" sx={{ marginRight: 1 }} />,
  //         tooltip: 'This report has malformed data and cannot be displayed',
  //       }
  //     : undefined;

  const reportName = useMemo(() => _reports.find((x) => x.id === reportId)?.name ?? '', [reportId, _reports]);

  const reports = useMemo(() => {
    const _ = _counter;
    return organize(_reports);
  }, [_counter, _reports]);

  return {
    open,
    ready,
    onClose,
    onClick,
    reports,
    anchorEl,
    infoIcon: null,
    reportName,
    refreshReports,
    onReportClick,
    organization: userProfile?.organization ?? '',
  };
}

// type InfoIcon =
//   | {
//       icon: React.ReactElement;
//       tooltip: React.ReactNode;
//     }
//   | undefined;

type PartialReportWithEnv = PartialReport & {
  env: 'Dev' | 'Prod';
};

type PartialReportsWithEnv = {
  name: string;
  variants: PartialReportWithEnv[];
};

function organize(vendors: PartialReport[]) {
  return vendors
    .map((x) => ({
      ...x,
      isFavorite: isEnvironmentFavorited(x.name),
    }))
    .sort((a, b) => {
      if (a.isFavorite) {
        if (b.isFavorite) {
          return a.name.localeCompare(b.name);
        }
        return -1;
      } else if (b.isFavorite) {
        return 1;
      } else {
        return a.name.localeCompare(b.name);
      }
    });
  const PROD_REGEX = /^([^\n]*?)(\s(\-\s)?prod)?$/gim;
  const DEV_REGEX = /^([^\n]*?)\s(\-\s)?(dev|development|non-prod|non-production)$/gim;
  const prod: PartialReportWithEnv[] = [];
  const dev: PartialReportWithEnv[] = [];

  vendors.forEach((vendor) => {
    // try dev first, as it is exclusive
    const devMatch = DEV_REGEX.exec(vendor.name);
    DEV_REGEX.lastIndex = 0;
    if (devMatch?.length && devMatch?.length > 1) {
      dev.push({
        ...vendor,
        env: 'Dev',
        name: devMatch[1],
      });
      return;
    }
    const prodMatch = PROD_REGEX.exec(vendor.name);
    PROD_REGEX.lastIndex = 0;
    if (prodMatch?.length && prodMatch?.length > 1) {
      prod.push({
        ...vendor,
        env: 'Prod',
        name: prodMatch[1],
      });
    }
  });

  const final: PartialReportsWithEnv[] = [];

  prod.forEach((vendor) => {
    let i = final.findIndex((f) => f.name === vendor.name);
    if (i < 0) {
      i =
        final.push({
          name: vendor.name,
          variants: [],
        }) - 1;
    }
    final[i].variants.push({
      ...vendor,
      name: 'Prod',
    });
  });

  dev.forEach((vendor) => {
    let i = final.findIndex((f) => f.name === vendor.name);
    if (i < 0) {
      i =
        final.push({
          name: vendor.name,
          variants: [],
        }) - 1;
    }
    final[i].variants.push({
      ...vendor,
      name: 'Dev',
    });
  });

  //return final;
}
