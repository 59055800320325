import { z } from 'zod';

import { PostfixFunctionSchema, PostfixRegexSchema } from '../postfixLogic';

export const ApiSourceOfTruthCustomizationCustomRuleSchema = z
  .object({
    id: z.string(),
    variablePath: z.string(),
    isOptional: z.union([z.boolean(), PostfixFunctionSchema]),
    qualifierId: z.preprocess((data) => data ?? undefined, z.string().optional()),
    pageUrlMatcher: z.union([z.string(), z.boolean(), PostfixRegexSchema]).optional(),
    value: z.preprocess((data) => {
      const workingData = Array.isArray(data) ? data : [data];
      const newWorkingData = workingData.map((item) => {
        if (typeof item === 'boolean') {
          return `${item}`;
        } else {
          return item ?? undefined;
        }
      });

      if (Array.isArray(data)) {
        return newWorkingData;
      } else {
        return newWorkingData[0] ?? undefined;
      }
    }, z.union([z.string(), z.array(z.union([z.string(), z.number()])), PostfixFunctionSchema, PostfixRegexSchema]).optional()),
    valueType: z.union([z.literal('s'), z.literal('f'), z.literal('r'), z.literal('e')]),
    friendlyName: z.string().optional(),
    variablePathFriendlyName: z.string().optional(),
    isEnabled: z.boolean(),
    variablePathTranslations: z
      .object({
        friendlyName: z.string(),
        technicalName: z.string().optional(),
      })
      .optional(),
  })
  .transform((data) => {
    const { variablePathTranslations, ...rest } = data;
    return {
      ...rest,
      variablePathFriendlyName: `${variablePathTranslations?.friendlyName} (${rest.variablePath})`,
    };
  });

export type ApiSourceOfTruthCustomizationCustomRule = z.infer<typeof ApiSourceOfTruthCustomizationCustomRuleSchema>;
